* {
  scroll-behavior: smooth !important;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes move {
  0%,
  100% {
    height: 50px;
  }

  50% {
    height: 30px;
  }
}

@-moz-keyframes move {
  0%,
  100% {
    height: 50px;
  }

  50% {
    height: 30px;
  }
}

@-o-keyframes move {
  0%,
  100% {
    height: 50px;
  }

  50% {
    height: 30px;
  }
}

@keyframes move {
  0%,
  100% {
    height: 50px;
  }

  50% {
    height: 30px;
  }
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-animation: rotate 6s infinite;
  -moz-animation: rotate 6s infinite;
  -o-animation: rotate 6s infinite;
  animation: rotate 6s infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

.line {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 3px;
  height: 50px;
  background: #ff8600;
  border-radius: 5px;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  -moz-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}

.line:nth-child(1) {
  -ms-transform: rotate(18deg);
  -webkit-transform: rotate(18deg);
  transform: rotate(18deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(2) {
  -ms-transform: rotate(36deg);
  -webkit-transform: rotate(36deg);
  transform: rotate(36deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(3) {
  -ms-transform: rotate(54deg);
  -webkit-transform: rotate(54deg);
  transform: rotate(54deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(4) {
  -ms-transform: rotate(72deg);
  -webkit-transform: rotate(72deg);
  transform: rotate(72deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(5) {
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(6) {
  -ms-transform: rotate(108deg);
  -webkit-transform: rotate(108deg);
  transform: rotate(108deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(7) {
  -ms-transform: rotate(126deg);
  -webkit-transform: rotate(126deg);
  transform: rotate(126deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(8) {
  -ms-transform: rotate(144deg);
  -webkit-transform: rotate(144deg);
  transform: rotate(144deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(9) {
  -ms-transform: rotate(162deg);
  -webkit-transform: rotate(162deg);
  transform: rotate(162deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(10) {
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(11) {
  -ms-transform: rotate(198deg);
  -webkit-transform: rotate(198deg);
  transform: rotate(198deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(12) {
  -ms-transform: rotate(216deg);
  -webkit-transform: rotate(216deg);
  transform: rotate(216deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(13) {
  -ms-transform: rotate(234deg);
  -webkit-transform: rotate(234deg);
  transform: rotate(234deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(14) {
  -ms-transform: rotate(252deg);
  -webkit-transform: rotate(252deg);
  transform: rotate(252deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(15) {
  -ms-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(16) {
  -ms-transform: rotate(288deg);
  -webkit-transform: rotate(288deg);
  transform: rotate(288deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(17) {
  -ms-transform: rotate(306deg);
  -webkit-transform: rotate(306deg);
  transform: rotate(306deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(18) {
  -ms-transform: rotate(324deg);
  -webkit-transform: rotate(324deg);
  transform: rotate(324deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.line:nth-child(19) {
  -ms-transform: rotate(342deg);
  -webkit-transform: rotate(342deg);
  transform: rotate(342deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
}

.line:nth-child(20) {
  -ms-transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  -webkit-animation: move 2s infinite;
  -moz-animation: move 2s infinite;
  -o-animation: move 2s infinite;
  animation: move 2s infinite;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

a {
  color: #000000;
}

.active {
  color: #ff8600;
}

.slider {
  color: #fff;
}

.slider .swiper-container1 {
  width: 100%;
  height: 100%;
}

.slider .swiper-container2 {
  width: 100%;
  height: 100%;
}

.slider__flex {
  display: flex;
  align-items: flex-start;
}

.slider__col {
  display: flex;
  flex-direction: column;
  margin-right: 8px;
  position: relative;
}

.slider__prev,
.slider__next {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #000000;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.slider__prev {
  top: 0;
}

.slider__next {
  bottom: 0;
}

.slider__prev:focus,
.slider__next:focus {
  outline: none;
}

.slider__thumbs {
  height: 330px;
  width: 100px;
}

.slider__thumbs .slider__image {
  transition: 0.25s;
  /* -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
  /* opacity: 0.5; */
}

.slider__thumbs .slider__image:hover {
  opacity: 1;
}

.slider__thumbs .swiper-slide-thumb-active .slider__image {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  border: 1px solid #ff8600;
}

.slider__images {
  height: 330px;
  width: 330px;
}

.slider__images .slider__image img {
  transition: 3s;
}

/* .slider__images .slider__image:hover img {
  transform: scale(1.1);
} */

.slider__image {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.slider__image img,
.slider__image video {
  display: block;
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  background-color: #ff86001a;
}

.slider__image .react_lightgallery_item {
  height: 100% !important;
  cursor: pointer;
}

@media (max-width: 1366px) {
  .slider2 .slider__images {
    height: 330px;
    width: 310px;
  }
}

@media (max-width: 767.98px) {
  .slider__flex {
    flex-direction: column-reverse;
  }

  .slider__col {
    flex-direction: row;
    align-items: center;
    margin-top: 24px;
    width: 100%;
  }

  .slider__images {
    width: 100%;
    max-height: 200px;
  }

  .slider__thumbs {
    height: 90px;
    width: 100%;
  }

  .slider__prev,
  .slider__next {
    width: 8px;
    outline: none;
    rotate: 270deg;
  }

  .slider__prev {
    left: 5%;
    top: 25%;
  }

  .slider__next {
    right: 5%;
    top: 25%;
  }

  .product_slider {
    flex-direction: column !important;
    gap: 20px;
    width: 350px;
  }

  .swiper {
    width: 100% !important;
  }

  .product_slider .mySwiper {
    height: 100px !important;
  }

  .product_slider_left .slider__prev,
  .product_slider_left .slider__next {
    width: 8px !important;
  }

  .product_slider_left .slider__next {
    right: 5%;
    top: 25%;
  }

  .product_slider_left .slider__prev {
    left: 5%;
    top: 25% !important;
  }
}

@media (max-width: 600px) {
  .product_slider .swiper {
    width: 100% !important;
  }
}

@media (max-width: 428px) {
  .product_slider {
    width: 300px;
  }
}

@media (max-width: 390px) {
  .product_slider {
    width: 240px;
  }

  .product_slider .swiper {
    height: 230px !important;
  }

  .product_slider .mySwiper {
    height: 100px !important;
  }
}

.product_slider .swiper {
  width: 350px;
  height: 300px;
  margin-left: auto;
  margin-right: auto;
}

.product_slider .swiper-slide {
  background-size: cover;
  background-position: center;
}

.product_slider .mySwiper {
  width: 100%;
}

.product_slider .mySwiper .swiper-slide {
  width: 100%;
  height: 100%;
  opacity: 0.4;
}

.product_slider .mySwiper .swiper-slide-thumb-active {
  opacity: 1;
  border: 1px solid #ff8600;
  padding: 4px;
  border-radius: 20px;
}

.product_slider .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product_slider {
  display: flex;
  flex-direction: row-reverse;
  position: relative;
}

.product_slider_left {
  position: relative;
  margin-right: 10px !important;
}

.product_slider_left .slider__prev,
.product_slider_left .slider__next {
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-user-select: none;
  user-select: none;
  color: #000000;
  position: absolute;
  z-index: 99;
  width: 100%;
}

.product_slider_left .slider__prev {
  top: 0;
}

.product_slider_left .slider__next {
  bottom: 0;
}

.swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-pagination-bullet {
  width: 12px !important;
  height: 12px !important;
  background-color: #ff8600 !important;
  opacity: 0.4 !important;
}

.swiper-pagination-bullet-active {
  width: 18px !important;
  height: 18px !important;
  opacity: 1 !important;
}

.marquee {
  width: 100%;
  background-color: white;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 6px;
}

.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 45s linear infinite;
}

.marqueeLandingPage {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}

.marqueeLandingPage > div {
  display: inline-flex;
  /* padding-left: 100vw; */
  animation: marqueeLandingPage 40s linear infinite;
}

@keyframes marqueeLandingPage {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-50%, 0);
  }
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.audio-recorder {
  background-color: transparent !important;
  box-shadow: none !important;
  font-family: 'Poppins', sans-serif !important;
}

.audio-recorder-timer,
.audio-recorder-status {
  font-family: 'Poppins', sans-serif !important;
}

.audio-player {
  margin-bottom: -10px !important;
}

.audio-player audio::-webkit-media-controls-current-time-display,
.audio-player audio::-webkit-media-controls-time-remaining-display {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500;
}

audio::-webkit-media-controls {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.swiper-image-landing {
  border-radius: 12px !important;
  object-fit: cover !important;
}

.swiper-image-landing-home {
  border-radius: 12px !important;
  object-fit: cover !important;
}

@media (max-width: 767.98px) {
  .swiper-image-landing-home {
    border-radius: 0px !important;
    object-fit: cover !important;
  }
}

.ck-editor__editable {
  min-height: 600px !important;
}

audio::-webkit-media-controls-volume-slider {
  display: none !important;
}

audio::-webkit-media-controls-mute-button {
  display: none !important;
}

audio::-webkit-media-controls-volume-control {
  display: none !important;
}

@media screen and (max-width: 320px) {
  .mobileLogo {
    display: none;
  }
}

@media (max-width: 767.98px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: block;
    text-align: center;
  }

  .fc-header-toolbar .fc-toolbar-chunk {
    display: block;
    margin-top: 10px;
  }
}

.fc-button-primary {
  background-color: #130146 !important;
}

.fc-day-today {
  background: #ff86003b !important;
  border: none !important;
}

.fc-scrollgrid {
  border-collapse: separate !important;
  border: 1px solid #ff860099 !important;
  border-radius: 20px !important;
  background-color: #ffffff !important;
  box-shadow: 0px 0px 10px 0px #00000014 !important;
}

.fc-col-header-cell .fc-scrollgrid-sync-inner {
  background-color: #ff8600 !important;
  height: 50px;
  font-size: 20px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fc-day-sun .fc-scrollgrid-sync-inner {
  border-radius: 20px 0 0 0 !important;
}

.fc-day-sat .fc-scrollgrid-sync-inner {
  border-radius: 0 20px 0 0 !important;
}

.fc-col-header-cell-cushion {
  font-weight: 500;
  color: #ffffff;
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-right: 0 !important;
  border-bottom: 0 !important;
}

.fc-scrollgrid th {
  border-top: none !important;
}

.fc-scrollgrid td:first-child,
.fc-scrollgrid th:first-child {
  border-left: none !important;
}

.fc .fc-bg-event {
  opacity: 1;
  background-color: transparent !important;
  background: none !important;
}

.fc .fc-daygrid-day-frame {
  height: 95px;
}

/* .fc .fc-daygrid-day {
  height: 100px !important;
  width: 100px !important;
}

.fc .fc-day {
  width: 100px !important;
} */

/* .fc .fc-dayGridMonth-view {
  overflow-x: auto !important;
}

.fc .fc-dayGridMonth-view .fc-scrollgrid {
  width: 100% !important;
}

.fc .fc-col-header-cell,
.fc .fc-daygrid-day {
  min-width: 150px !important;
} */

.infinite-scroll-component__outerdiv {
  width: 100%;
}

#reload_href {
  color: #ff8600 !important;
  font-size: 12px !important;
  text-align: center !important;
  font-weight: 600 !important;
}
